module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Angie Thomas","short_name":"Angie Thomas","start_url":"/","background_color":"#FFFFFF","theme_color":"#D70C0C","display":"standalone","include_favicon":false,"legacy":false,"icons":[{"src":"/icons/angie-thomas-16.png","sizes":"16x16","type":"image/png"},{"src":"/icons/angie-thomas-32.png","sizes":"32x32","type":"image/png"},{"src":"/icons/angie-thomas-64.png","sizes":"64x64","type":"image/png"},{"src":"/icons/angie-thomas-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/icons/angie-thomas-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
