/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({location}) => {
  anchorScroll(location);
  return true;
};
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  anchorScroll(location);
  return true;
}

function anchorScroll(location){
  const heroPanels = ['#author', '#producer', '#speaker', '#activist'];
  // Check for location, hash, and make sure it's not a hero panel
  if (location && location.hash && heroPanels.indexOf(location.hash) == -1) {
    setTimeout(() => {
      document.querySelector(location.hash).scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   const item = document.querySelector(location.hash).offsetTop;
    //   const mainNavHeight = document.querySelector(nav).offsetHeight;
    //   window.scrollTo({top: item - mainNavHeight, left: 0, behavior: 'smooth'});
    }, 0);
  }
}
